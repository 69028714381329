<template>
  <v-row class="match-height">
    <v-col md="12" cols="12">
      <v-card>
        <v-tabs v-model="tab" class="tab-index">
          <v-tab v-for="item in items" :key="item">
            {{ item }}
          </v-tab>
        </v-tabs>

        <v-tabs-items v-model="tab">
          <v-tab-item v-for="item in items" :key="item" class="mt-2">
            <tagihan-harian v-if="item === 'UPAH HARIAN'"></tagihan-harian>
            <tagihan-mingguan v-if="item === 'UPAH MINGGUAN'"></tagihan-mingguan>
            <tagihan-bulanan v-if="item === 'UPAH BULANAN'"></tagihan-bulanan>
          </v-tab-item>
        </v-tabs-items>
      </v-card>
    </v-col>
  </v-row>
</template>

<script>
import axios from 'axios'
import TagihanHarian from './TagihanHarian.vue'
import TagihanMingguan from './TagihanMingguan.vue'
import TagihanBulanan from './TagihanBulanan.vue'

const apiRoot = process.env.VUE_APP_APIURL

export default {
  components: {
    TagihanHarian,
    TagihanMingguan,
    TagihanBulanan,
  },
  data() {
    return {
      items: ['UPAH HARIAN', 'UPAH MINGGUAN', 'UPAH BULANAN'],
      // items: ['UPAH BULANAN'],
      tab: null,
    }
  },
  created() {
    // this.getDataFromApi()
  },
  computed: {
    token() {
      return this.$store.getters.token
    },
  },
  watch: {
    options: {
      handler() {
        this.getDataFromApi()
      },
      deep: true,
    },
  },
  methods: {
    initialize() {},
    getDataFromApi() {
      this.loading = true

      // const { page, itemsPerPage } = this.options
      const headers = {
        Authorization: this.token,
      }
      const form = new FormData()
      form.append('tgl_simpanan', this.date_harian)
      axios
        .post(`${apiRoot}/api/Simpanan/getTagihanAnggota_harian`, form, { headers })
        .then(response => {
          if (response.data.code === 401) {
            this.$store.dispatch('logout', 'Session berakhir!')
          } else if (response.data.code === 200) {
            this.desserts = response.data.data
            this.totalDesserts = response.data.total
            this.loading = false
          } else {
            alert(response.data.message)
          }
        })
        .catch(error => {
          alert(error)
        })
    },
    selectAll() {
      this.selected = []
      if (this.selectedAll === true) {
        this.desserts.forEach(item => {
          if (item.sudah_bayar === null) {
            this.selected.push(`${item.id_jenis_simpanan}::${item.besar_simpanan}::${item.id_tabungan}`)
          }
        })
      } else {
        this.selected = []
      }
    },
    simpan() {
      this.loadingSimpan = true
      const headers = {
        Authorization: this.token,
      }
      const form = new FormData()
      form.append('datas', this.selected)
      axios
        .post(`${apiRoot}/api/Simpanan/addBatch`, form, { headers })
        .then(response => {
          if (response.data.code === 401) {
            this.$store.dispatch('logout', 'Session berakhir!')
          } else if (response.data.code === 200) {
            this.getDataFromApi()
          } else {
            alert(response.data.message)
          }
          this.loadingSimpan = false
        })
        .catch(error => {
          alert(error)
        })
    },
  },
}
</script>
