<template>
  <v-row class="match-height">
    <!-- horizontal -->
    <v-col cols="12" md="12">
      <v-card>
        <v-simple-table class="elevation-1">
          <template v-slot:top>
            <v-toolbar flat>
              <v-toolbar-title>Tagihan Upah Mingguan</v-toolbar-title>
              <v-divider class="mx-4" inset vertical></v-divider>
              <!-- <input type="date" v-model="editedItem.date_harian" style="border: 1px solid grey" /> -->
              <v-text-field
                v-model="editedItem.date_harian"
                type="date"
                dense
                outlined
                hide-details
              ></v-text-field>
              <v-divider class="mx-4" inset vertical></v-divider>
              <v-text-field
                label="Nama/ kode / bagian/ wilayah"
                v-model="filter"
                outlined
                dense
                hide-details
              ></v-text-field>
              <v-divider class="mx-4" inset vertical></v-divider>
              <v-btn color="primary" small @click="getDataFromApi()">Lihat</v-btn>
              &nbsp;
              <v-btn color="secondary" small @click="cetak()">Cetak</v-btn>
              &nbsp;
              <v-btn color="secondary" small @click="export_excel()">Export Excel</v-btn>
            </v-toolbar>
          </template>

          <template v-slot:default>
            <thead>
              <tr>
                <th style="text-align: center">NO</th>
                <th style="text-align: center">ANGGOTA</th>
                <th style="text-align: center">BAGIAN</th>
                <th style="text-align: center">WILAYAH</th>
                <th style="text-align: center">BESAR PINJAMAN</th>
                <th style="text-align: center">ANGS (X)</th>
                <th style="text-align: center">SISA POKOK</th>
                <th style="text-align: center">ANGS.POKOK</th>
                <th style="text-align: center">BUNGA</th>
                <th style="text-align: center">S.WAJIB</th>
                <th style="text-align: center">TABUNGAN</th>
                <th style="text-align: center">TOTAL</th>
                <th style="text-align: center">AKSI</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(item, index) in desserts" :key="index">
                <td class="text-center">{{ index + 1 }}</td>
                <td>{{ item.nama + ' (' + item.kode_anggota + ')' }}</td>
                <td>{{ item.divisi }}</td>
                <td>{{ item.wilayah }}</td>
                <td style="text-align: right">
                  {{ new Intl.NumberFormat(['id']).format(item.pinjaman.besar_pinjam) }}
                </td>
                <td style="text-align: right">
                  {{
                    new Intl.NumberFormat(['id']).format(item.pinjaman.angsuran_ke) +
                    '(' +
                    item.pinjaman.lama_angsuran +
                    ')'
                  }}
                </td>
                <td style="text-align: right">
                  {{ new Intl.NumberFormat(['id']).format(item.pinjaman.sisa_pokok) }}
                </td>
                <td style="text-align: right">
                  {{ new Intl.NumberFormat(['id']).format(item.pinjaman.besar_angsuran) }}
                </td>
                <td style="text-align: right">{{ new Intl.NumberFormat(['id']).format(item.pinjaman.bunga) }}</td>
                <td style="text-align: right">
                  {{ new Intl.NumberFormat(['id']).format(item.simpanan_wajib.besar_simpanan) }}
                </td>
                <td style="text-align: right">
                  {{ new Intl.NumberFormat(['id']).format(item.tabungan.besar_default) }}
                </td>
                <td style="text-align: right">
                  {{
                    new Intl.NumberFormat(['id']).format(
                      parseFloat(item.simpanan_wajib.besar_simpanan) +
                        parseFloat(item.tabungan.besar_default) +
                        parseFloat(item.pinjaman.besar_angsuran) +
                        parseFloat(item.pinjaman.bunga),
                    )
                  }}
                </td>
                <td>
                  <v-checkbox
                    v-model="selected"
                    v-if="item.sudah_bayar === null"
                    label="Bayar"
                    :value="
                      'WAJIB=' +
                      item.simpanan_wajib.sudah_bayar +
                      '::' +
                      item.simpanan_wajib.id +
                      '::' +
                      item.simpanan_wajib.besar_simpanan +
                      '::' +
                      item.id +
                      '>' +
                      'TABUNGAN=' +
                      item.tabungan.sudah_bayar +
                      '::' +
                      item.tabungan.besar_default +
                      '::' +
                      item.tabungan.id +
                      '>' +
                      'PINJAMAN=' +
                      item.pinjaman.sudah_bayar +
                      '::' +
                      item.pinjaman.id +
                      '::' +
                      item.pinjaman.besar_angsuran +
                      '::' +
                      item.pinjaman.bunga +
                      '::' +
                      item.pinjaman.angsuran_ke
                    "
                  ></v-checkbox>
                  <span v-else style="background: green; border-radius: 3px; padding: 5px 10px; color: white"
                    >Sudah Bayar</span
                  >
                </td>
              </tr>
            </tbody>
            <tfoot>
              <tr>
                <td colspan="6" style="text-align: center">TOTAL</td>
                <td style="text-align: right">
                  {{ new Intl.NumberFormat(['id']).format(footer.totalSisaPinjamanPokok) }}
                </td>
                <td style="text-align: right">{{ new Intl.NumberFormat(['id']).format(footer.totalPokok) }}</td>
                <td style="text-align: right">{{ new Intl.NumberFormat(['id']).format(footer.totalBnga) }}</td>
                <td style="text-align: right">{{ new Intl.NumberFormat(['id']).format(footer.totalWajib) }}</td>
                <td style="text-align: right">{{ new Intl.NumberFormat(['id']).format(footer.totalTabungan) }}</td>
                <td style="text-align: right">{{ new Intl.NumberFormat(['id']).format(footer.total) }}</td>
                <td>
                  <v-checkbox class="mt-4" v-model="selectedAll" @click="selectAll()" label="Pilih Semua"></v-checkbox>
                  <v-btn class="mb-2" color="primary" :loading="loadingSimpan" @click="simpan()">Simpan</v-btn>
                </td>
              </tr>
            </tfoot>
          </template>
        </v-simple-table>
      </v-card>
    </v-col>
  </v-row>
</template>

<style scoped>
.select .v-input__slot {
  padding-right: 4px;
}
table tbody tr td,
table thead tr th,
table tfoot tr td {
  border: 1px solid grey;
}
</style>

<script>
import axios from 'axios'

const apiRoot = process.env.VUE_APP_APIURL

export default {
  data() {
    return {
      // datatable
      desserts: [],
      loading: true,
      footer: {
        totalWajib: 0,
        totalTabungan: 0,
        totalPokok: 0,
        totalBnga: 0,
        totalSisaPinjamanPokok: 0,
        total: 0,
      },

      selected: [],
      selectedAll: false,
      loadingSimpan: false,
      editedItem: {
        minggu: '0',
        date_harian: new Date().toJSON().slice(0, 10).replace(/-/g, '-'),
      },
        filter:'',
    }
  },
  created() {
    this.getDataFromApi()
  },
  computed: {
    token() {
      return this.$store.getters.token
    },
  },
  watch: {
    editedItem: {
      handler() {
        this.getDataFromApi()
      },
      deep: true,
    },
  },
  methods: {
    getDataFromApi() {
      this.$store.dispatch('loading', true)

      const headers = {
        Authorization: this.token,
      }
      axios
        .get(`${apiRoot}/api/Simpanan/getTagihanAnggota_mingguan/token/get/${this.editedItem.date_harian}/${this.filter}`, {
          headers,
        })
        .then(response => {
          if (response.data.code === 401) {
            this.$store.dispatch('logout', 'Session berakhir!')
          } else if (response.data.code === 200) {
            this.selected = []
            this.desserts = response.data.data

            this.footer.totalWajib = 0
            this.footer.totalSisaPinjamanPokok = 0
            this.footer.totalTabungan = 0
            this.footer.totalPokok = 0
            this.footer.totalBnga = 0
            this.footer.total = 0
            response.data.data.forEach(item => {
              this.footer.totalSisaPinjamanPokok += parseFloat(item.pinjaman.sisa_pokok)
              this.footer.totalWajib += parseFloat(item.simpanan_wajib.besar_simpanan)
              this.footer.totalTabungan += parseFloat(item.tabungan.besar_default)
              this.footer.totalPokok += parseFloat(item.pinjaman.besar_angsuran)
              this.footer.totalBnga += parseFloat(item.pinjaman.bunga)
              this.footer.total +=
                parseFloat(item.simpanan_wajib.besar_simpanan) +
                parseFloat(item.tabungan.besar_default) +
                parseFloat(item.pinjaman.besar_angsuran) +
                parseFloat(item.pinjaman.bunga)
            })
          } else {
            alert(response.data.message)
          }
          this.$store.dispatch('loading', false)
        })
        .catch(error => {
          alert(error)
          this.$store.dispatch('loading', false)
        })
    },
    selectAll() {
      this.selected = []
      if (this.selectedAll === true) {
        this.desserts.forEach(item => {
          if (item.sudah_bayar === null) {
            this.selected.push(
              'WAJIB=' +
                item.simpanan_wajib.sudah_bayar +
                '::' +
                item.simpanan_wajib.id +
                '::' +
                item.simpanan_wajib.besar_simpanan +
                '::' +
                item.id +
                '>' +
                'TABUNGAN=' +
                item.tabungan.sudah_bayar +
                '::' +
                item.tabungan.besar_default +
                '::' +
                item.tabungan.id +
                '>' +
                'PINJAMAN=' +
                item.pinjaman.sudah_bayar +
                '::' +
                item.pinjaman.id +
                '::' +
                item.pinjaman.besar_angsuran +
                '::' +
                item.pinjaman.bunga +
                '::' +
                item.pinjaman.angsuran_ke,
            )
          }
        })
      } else {
        this.selected = []
      }
    },
    simpan() {
      this.loadingSimpan = true
      const headers = {
        Authorization: this.token,
      }
      const form = new FormData()
      form.append('datas', this.selected)
      form.append('tanggal', this.editedItem.date_harian)
      form.append('jenis_upah', 'MINGGUAN')
      axios
        .post(`${apiRoot}/api/Simpanan/addBatch_baru`, form, { headers })
        .then(response => {
          if (response.data.code === 401) {
            this.$store.dispatch('logout', 'Session berakhir!')
          } else if (response.data.code === 200) {
            this.getDataFromApi()
          } else {
            alert(response.data.message)
          }
          this.loadingSimpan = false
        })
        .catch(error => {
          alert(error)
          this.loadingSimpan = false
        })
    },

    cetak() {
      window.open(
        `${apiRoot}/api/Simpanan/getTagihanAnggota_mingguan/${this.token}/cetak/${this.editedItem.date_harian}`,
        '_blank',
      )
    },
    export_excel() {
      window.open(
        `${apiRoot}/api/Simpanan/getTagihanAnggota_mingguan/${this.token}/excel/${this.editedItem.date_harian}`,
        '_blank',
      )
    },
  },
}
</script>
